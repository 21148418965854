/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatRelative } from 'date-fns';
import { enUS, ru } from 'date-fns/locale';
import _ from 'lodash';

import { routePaths } from 'utils/constants';
import { useAppSelector } from 'utils/hooks';
import StyledNotificationRecord, {
  StyledNotificationsLink,
} from './NotificationRecord.styles';
import NotificationTypeIcon from './components/NotificationTypeIcon';

const NotificationRecord: React.FC<any> = ({ record }) => {
  const { t } = useTranslation();
  const { lang } = useAppSelector(({ main }) => main);

  // TODO create type enum for event type
  const formatNotificationMessage = (record: any) => {
    switch (record.type) {
      case 'Vacancy_status_updated': {
        return (
          <>
            <span>{t('vacancy')} </span>
            <StyledNotificationsLink
              to={routePaths.vacancy.createLink(record.vacancyId)}
            >
              {record.vacancyTitle}
            </StyledNotificationsLink>
            <span>
              {' '}
              {t('status has been updated')} {t('to')} {record.newVacancyStatus}
            </span>
          </>
        );
      }

      case 'Vacancy_created': {
        return (
          <>
            <span>{t('vacancy')} </span>
            <StyledNotificationsLink
              to={routePaths.vacancy.createLink(record.vacancyId)}
            >
              {record.vacancyTitle}
            </StyledNotificationsLink>
            <span> {t('has been created')}</span>
          </>
        );
      }

      case 'Candidate_added': {
        return (
          <>
            <span>{t('new candidate')} </span>
            <StyledNotificationsLink
              to={routePaths.candidate.createLink(record.candidateId)}
            >
              {record.candidateName}
            </StyledNotificationsLink>
            <span> {t('was added to vacancy')} </span>
            <StyledNotificationsLink
              to={routePaths.vacancy.createLink(record.vacancyId)}
            >
              {record.vacancyTitle}
            </StyledNotificationsLink>
          </>
        );
      }

      case 'Candidate_changed': {
        return (
          <>
            <span>{record.comment} </span>
            <StyledNotificationsLink
              to={routePaths.candidate.createLink(record.candidateId)}
            >
              {record.candidateName}
            </StyledNotificationsLink>
            <span> {t('on vacancy')} </span>
            <StyledNotificationsLink
              to={routePaths.vacancy.createLink(record.vacancyId)}
            >
              {record.vacancyTitle}
            </StyledNotificationsLink>
          </>
        );
      }

      case 'Candidate_comment_added': {
        return (
          <>
            <span>{t('new comment to')} </span>
            <StyledNotificationsLink
              to={routePaths.candidate.createLink(record.candidateId)}
            >
              {record.candidateName}
            </StyledNotificationsLink>
            <span> {t('for the vacancy')}</span>{' '}
            <StyledNotificationsLink
              to={routePaths.vacancy.createLink(record.vacancyId)}
            >
              {record.vacancyTitle}
            </StyledNotificationsLink>
          </>
        );
      }

      case 'Candidate_status_updated': {
        return (
          <>
            <span>{t('candidate')} </span>
            <StyledNotificationsLink
              to={routePaths.candidate.createLink(record.candidateId)}
            >
              {record.candidateName}
            </StyledNotificationsLink>
            <span>
              {' '}
              {t('status has been updated to')} {record.newStatus}
            </span>
          </>
        );
      }

      case 'Vacancy_shared': {
        return (
          <>
            <StyledNotificationsLink
              to={routePaths.manager.createLink(record.fromId)}
            >
              <span>{record.sharedByUserName} </span>
            </StyledNotificationsLink>
            <span>{t('shared a vacancy')} </span>
            <StyledNotificationsLink
              to={routePaths.vacancy.createLink(record.vacancyId)}
            >
              {record.vacancyTitle}
            </StyledNotificationsLink>{' '}
            <span>{t('to you')}</span>
          </>
        );
      }

      case 'Client_added': {
        return (
          <>
            <span>{t('new company')}</span>
            <StyledNotificationsLink
              to={routePaths.company.createLink(record.clientId)}
            >
              <span> {record.clientName} </span>
            </StyledNotificationsLink>
            <span>{t('was assigner to you')} </span>
          </>
        );
      }

      default:
        return null;
    }
  };

  const message = formatNotificationMessage(record);
  const today = new Date();
  const createdAt = new Date(record.createdAt);

  const dateStamp = _.upperFirst(
    formatRelative(createdAt, today, {
      locale: lang === 'ru' ? ru : enUS,
    }),
  );

  return (
    <StyledNotificationRecord>
      <div className="wrap">
        <div className="content">
          <div className="record-company-name">
            <Link to={routePaths.company.createLink(record.companyId)}>
              {record.companyName}
            </Link>
          </div>
          <div className="record-type-icon">
            <NotificationTypeIcon type={record.iconType} />
          </div>
          <div className="record-message">
            <div className="record-message-text">{message}</div>
            <div className="record-message-time">{dateStamp}</div>
          </div>
          <div className="record-is-read-icon">
            {!record.isRead && <div className="record-is-read-icon-circle" />}
          </div>
        </div>
      </div>
    </StyledNotificationRecord>
  );
};

export default NotificationRecord;
